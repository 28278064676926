<template>
  <my-header></my-header>
  <div class="container">
    <div class="main">
      <!--<div class="top">-->
        <!--<el-input prefix-icon="el-icon-search" placeholder="搜索工作岗位"></el-input>-->
      <!--</div>-->

      <div class="mid">
        <!--<div class="mid_left">-->
          <!--<div class="mid_left_title"> 过滤条件 </div>-->
          <!--<div class="mid_left_select">-->
            <!--&lt;!&ndash;筛选条件&ndash;&gt;-->
            <!--<div class="items">-->
              <!--<el-collapse v-model="activeNames" @change="handleChange">-->
                <!--<el-collapse-item title="城市" name="1">-->
                  <!--<el-checkbox-group v-model="form.citySelect" class="citySelect">-->
                    <!--<el-checkbox-->
                        <!--v-for="item in cityList"-->
                        <!--:key="item.value"-->
                        <!--:label="item.value"-->

                    <!--&gt;-->
                      <!--{{ item.label }}-->
                    <!--</el-checkbox>-->
                  <!--</el-checkbox-group>-->
                <!--</el-collapse-item>-->
              <!--</el-collapse>-->
              <!--<el-collapse v-model="activeNames" @change="handleChange_2">-->
                <!--<el-collapse-item title="职业类别" name="2">-->
                  <!--<el-checkbox-group v-model="form.vocationalSelect" class="citySelect">-->
                    <!--<el-checkbox-->
                        <!--v-for="item in vocationalList"-->
                        <!--:key="item.value"-->
                        <!--:label="item.value"-->

                    <!--&gt;-->
                      <!--{{ item.label }}-->
                    <!--</el-checkbox>-->
                  <!--</el-checkbox-group>-->
                <!--</el-collapse-item>-->
              <!--</el-collapse>-->
              <!--<el-collapse v-model="activeNames" @change="handleChange_3">-->
                <!--<el-collapse-item title="招聘类型" name="3">-->
                  <!--<el-checkbox-group v-model="form.recruitmentSelect" class="citySelect">-->
                    <!--<el-checkbox-->
                        <!--v-for="item in recruitmentList"-->
                        <!--:key="item.value"-->
                        <!--:label="item.value"-->

                    <!--&gt;-->
                      <!--{{ item.label }}-->
                    <!--</el-checkbox>-->
                  <!--</el-checkbox-group>-->
                <!--</el-collapse-item>-->
              <!--</el-collapse>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
        <div class="mid_right">
          <div class="mid_right_title"> 招聘信息 </div>
          <div class="mid_right_box">
            <div class="item" v-for="item in jobList" :key="item">
              <div class="item_left">
                <div class="item_left_top">
                  <div class="left_name"> {{item.name}} </div>
                  <div class="left_money">  {{item.salary}}K</div>
                </div>
                <div class="item_left_mid"> {{item.intro}} </div>
                <div class="item_left_bottom">
                  <div class="info_item" >
                    <div class="info_item_hint">职责：</div>
                    <div class="info_item_title" v-for="item_2 in item.resList" :key="item_2">{{item_2.title}}</div>
                  </div>
                  <div class="info_item">
                    <div class="info_item_hint">要求：</div>
                    <div class="info_item_title" v-for="item_3 in item.reqList" :key="item_3">{{item_3.title}}</div>
                  </div>
                </div>
              </div>
              <div class="item_right">
                <div class="btn" @click.stop="toDetail(item)">查看详情</div>
                <a class="btn btn_2"
                  :href="
                  item.id == 1 ? 'https://www.zhipin.com/job_detail/361cf64fe3162b000nx62dS9E1U~.html?ka=search_list_jname_2_blank&lid=2Fks5DRZ5aK.search.2&securityId=Ih9W1DhVOgeCI-x1X3_F42WqGIE6o_DcMh0SR5gYLV6Zt7UaCEDRtT_4HXFy5qT_Qju5zolrFjDFReCZSQxIE8ZJeo3Kcjzb4fzXiOjPSBtQWvYR' :
                  item.id == 2 ? 'https://www.zhipin.com/job_detail/4ca9edd3400999810nd_3Ni-EVs~.html?ka=search_list_jname_10_blank&lid=2Fks5DRZ5aK.search.10&securityId=xQ-2DNIEf2dqg-21f4qlxdsazZBIiDpnxcear5PXYRIKFEkg5_bBhwQjCJB9Cjjo-gndOQwqrPaM2iRuoQ0Wydbfhx8-dgkWo4fpU1QVvPQRkfjW' :
                  item.id == 3 ? 'https://www.zhipin.com/job_detail/e38e5fe9b2f4ba230nx62dS9EVU~.html?ka=search_list_jname_4_blank&lid=2Fks5DRZ5aK.search.4&securityId=JsSxF14hv2AN6-O1D2rwuhBVY4vkSgHJ11qQqiaWq2RX48Kxw2IJvcknAooUoqfB3Q8HwtmkkZwr3xUm-soQJ7XLKZwqSO_LO9GIN0NpdnVOi-md' :
                  item.id == 4 ? 'https://www.zhipin.com/gongsir/aa445caa8331b5421XZ_2ty4GQ~~.html?page=1&ka=page-1' :
                  item.id == 5 ? 'https://www.zhipin.com/gongsir/aa445caa8331b5421XZ_2ty4GQ~~.html?page=1&ka=page-1' : ''"
                  target="_blank"
                >BOSS直聘</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "job",
    data(){
      return{
        screeList: [
          {}
        ],
        form:{
          citySelect:[],
          vocationalSelect: [],
          recruitmentSelect: [],
        },
        cityList: [
          { label: '海口', value: '海口' },
          { label: '北京', value: '北京' },
        ],
        vocationalList: [
          { label: '技术', value: '技术' },
          { label: '产品', value: '产品' },
          { label: '设计', value: '设计' },
          { label: '运营', value: '运营' },
          { label: '人事/财务/行政', value: '人事/财务/行政' },
          { label: '销售', value: '销售' },
        ],
        recruitmentList: [
          { label: '社会招聘', value: '社会招聘' },
          { label: '校园招聘应届生', value: '校园招聘应届生' },
          { label: '校园招聘实习生', value: '校园招聘实习生' },
        ],
        // 招聘信息
        jobList: [
          {
            id: 1,
            name: '产品经理（海口）',
            salary: '6-10',
            intro: '海口 | 技术 | 2 年以上 | 大专 | 社会招聘',
            // 岗位
            resList: [
              { title: '1、负责互联网＋政务便民服务的产品策划和实施；' },
              { title: '2、负责用户需求的挖掘、收集、归纳、整理和分析并输出相应分析报告；' },
              { title: '3、配合产品经理，参与协调产品的开发、测试和验收工作，及时掌握测试结果，保证产品质量；' },
              { title: '4、分析产品运营数据，不断完善和优化产品，提升产品的用户体验；' },
            ],
            // 要求
            reqList: [
              { title: '1、熟悉互联网产品的研发和运营流程，注重用户体验和交互设计；' },
              { title: '2、具备出色的逻辑分析能力和数据敏感性，思维严谨；' },
              { title: '3、熟悉项目管理流程、文档撰写能力较强；' },
              { title: '4、有良好的沟通能力，及团队协作能力；' },
              { title: '5、较强的抗压能力，责任心；' },
              { title: '6、2年以上工作经验。' },
            ],
          },
          {
            id: 2,
            name: '前端技术（海口）',
            salary: '5-8',
            intro: '海口 | 技术 | 2 年以上 | 大专 | 社会招聘',
            // 岗位
            resList: [
              { title: '1、2年以上前端开发经验' },
              { title: '2.、负责前端整体架构设计、工作流程制定和优化；' },
              { title: '3、与产品沟通完成快速迭代产品，持续优化前端交互体验；' },

            ],
            // 要求
            reqList: [
              { title: '1、熟练掌握至少一个MVVM框架（Vue、React）、掌握前端自动化构建工具；' },
              { title: '2、有微信小程序/公众号、支付宝小程序开发经验；' },
              { title: '3、具备较强的问题分析、处理能力以及高度的抽象设计能力，能够独立高效分析并解决问题；' },
              { title: '4、喜欢去看及尝试最新的技术，追求编写优雅的代码，从技术趋势和思路上能影响技术团队；' },
              { title: '5、表达沟通能力佳，富有团队协作意识。' },
            ],
          },
          {
            id: 3,
            name: '后端java开发（海口）',
            salary: '5-8',
            intro: '海口 | 技术 | 1-3 年 | 大专 | 社会招聘',
            resList: [
              { title: '1、参与项目需求的讨论，负责部分功能模块的开发；' },
              { title: '2、对开发任务能够准确理解，并且能够保质保量完成；' },
              { title: '3、喜欢去看及尝试最新的技术，追求编写优雅的代码，从技术趋势和思路上能影响技术团队；' },
              { title: '4、具备良好的表达和沟通能力；具备极强的团队精神和合作精神。\n' +
                  '技术上：' },
            ],
            // 要求
            reqList: [
              { title: '1、熟悉J2EE开源框架和技术，如Spring,Mybatis,Hibernate；' },
              { title: '2、熟练使用至少一种主流数据库 mysql,Postgresql,Oracle；' },
              { title: '3、熟悉HTML、JavaScript、CSS、XML等web开发领域及相关技术；' },
              { title: '4、熟悉W3C标准与ES规范，熟悉Web语义化;' },
              { title: '5、熟悉面向对象的程序设计方法，熟悉了解各种数据结构，算法模型以及常用设计模式；' },
              { title: '6、熟练代码版本管理工具svn,git；' },
            ],
          },
          {
            id: 4,
            name: '高级工程师（海口）',
            salary: '8-12',
            intro: '海口 | 运营 | 2 年以上 | 大专 | 社会招聘',
            resList: [
              { title: '1、互联网医疗相关系统的设计、开发；' },
              { title: '2、基础服务及组件相关的开发、维护；' },
            ],
            // 要求
            reqList: [
              { title: '1、具备扎实的编程基础和数据结构算法基础，优秀的编程能力和问题解决能力；' },
              { title: '2、Java及面向对象设计开发，深入理解面向服务的架构 ，Spring相关技术;' },
              { title: '3、熟悉微服务架构，熟悉Spring Cloud生态或Dubbo；' },
              { title: '4、熟悉数据库原理， 熟练使用一种以上的常见数据库产品；' },
              { title: '5、优秀的模块设计、抽象能力，能独立的进行系统设计与思考；' },
              { title: '6、熟练掌握网络通信原理，熟悉服务器端，数据端的主流技术或框架；' },
            ],
          },
          {
            id: 5,
            name: '产品测试工程师（海口）',
            salary: '4-6',
            intro: '海口 | 运营 | 2 年以上 | 大专 | 社会招聘',
            resList: [
              { title: '1、负责协助技术人员和产品经理，制定产品测试标准，并按照标准对产品进行系统测试；' },
              { title: '2、参与产品需求的评审工作，并提出改进意见 ；' },
              { title: '3、依据需求文档及设计文档，编写测试用例，测试计划，测试报告等相关文档；' },
              { title: '4、对互联网产品进行功能测试，稳定性测试等，完成产品的质量保证和发布；' },
              { title: '5、依据测试用例执行手工测试，规范提交Bug，跟进并推动Bug及时合理解决；' },
              { title: '6、按时完成软件测试工作任务，执行测试，跟踪缺陷状态，提交测试执行报告；' },
              { title: '7、配合开发人员解决工作中遇到的问题，并且对项目提出合理化建议。' },
            ],
            // 要求
            reqList: [
              { title: '1、计算机、通讯、电子类相关专业专科及以上学历；' },
              { title: '2、熟悉测试工作流程、测试理论知识，掌握互联网产品测试的方法和原理；' },
              { title: '3、熟练使用业界常用的BUG管理工具和测试工具禅道/LR等；' },
              { title: '4、具有良好的沟通理解能力和协调能力、前瞻创新能力，工作积极主动；' },
              { title: '5、有自动化测试经验优先；' },
            ],
          },
        ],
        hrefUrl: '',
        titleId: '',
      }

    },
    methods:{
      toDetail(item){
        localStorage.setItem('jobInfo',JSON.stringify(item))
        this.$router.push({
          name: 'job_detail',
        })
      }
    },
    created(){
    },
  };
</script>

<style lang="stylus" scoped>
  .container{
    background #F5F5F7
    display flex
    align-items center
    justify-content center
    .main{
      width 1640px
      .top{
        margin-top 60px
      }
      .mid{
        margin-top 50px
        display flex
        justify-content space-between
        .mid_left{
          display flex
          flex-flow column
          .mid_left_title{
            color #343A40
            font-size 28px
          }
          .mid_left_select{
            margin-top 26px
            width 398px
            border-radius 5px
            background #fff
            .items{
              margin 0 30px
            }
          }
        }
        .mid_right{
          display flex
          flex-flow column
          width 100%
          /*width 1226px*/
          .mid_right_title{
            color #343A40
            font-size 38px
            font-weight bold
          }
          .mid_right_box{
            display flex
            flex-flow column
            margin-top 26px
            .item{
              background #FFF
              border-radius 5px
              display flex
              justify-content space-between
              align-items center
              box-sizing border-box
              padding 30px 28px 30px 32px
              margin-bottom 20px
              .item_left{
                display flex
                flex-flow column
                .item_left_top{
                  display flex
                  font-size 24px
                  font-weight bold
                  .left_name{
                    color #343A40
                  }
                  .left_money{
                    color #007BFF
                    margin-left 20px
                  }
                }
                .item_left_mid{
                  font-size 16px
                  color #343A40
                  margin-top 11px
                }
                .item_left_bottom{
                  font-size 16px
                  color #6C757D
                  margin-top 21px
                  .info_item{
                    display flex
                    flex-flow column
                    .info_item_hint{
                      margin 14px 0 6px 0
                    }
                  }
                }
              }
              .item_right{
                display flex
                .btn{
                  width 104px
                  height 41px
                  line-height 41px
                  text-align center
                  border 1px solid rgba(52, 58, 64, .3)
                  border-radius 5px
                  font-size 16px
                  color #343A40
                  cursor pointer
                }
                .btn_2{
                  border none
                  background #5FD6C9
                  color #FFF
                  margin-left 29px
                  cursor pointer
                  text-decoration none
                }
              }
            }
          }
        }
      }
    }
  }
  .citySelect{
    display flex
    flex-flow column
    .el-checkbox{
      height 68px
      line-height 68px
    }
  }
  .item_name{
    font-size 68px
    color #343A40
  }
</style>
